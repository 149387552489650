/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import '../Extras/Heroes.css';
import '../../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

function HomeHero() {
	return (
		<div>
			<div className="IndexHero Hero">
				<h1 className="hero-banner-text montserrat-font white-text">Custom Software &</h1>
				<h1 className="dancing-font hero-banner-text orange-text">Professional Design</h1>
			</div>
		</div>
	);
}

function HomeContent() {
	return(
		<div>
			<div className="d-block py-5 row text-center">
				<h1 className="main-heading montserrat-font">Customized Software Solutions</h1>
				<p className="xlg-paragraph lg-margin xl-padding">
					I am a seasoned software engineer and web developer with extensive experience in designing, 
					developing, and deploying scalable applications. My journey began with a non-traditional path 
					into software engineering, which honed my ability to adapt and learn quickly. Over the years, 
					I’ve specialized in creating robust backend systems, intuitive frontends, and distributed architectures 
					that prioritize performance and reliability. From building feature-rich applications to troubleshooting 
					complex issues, I excel at crafting solutions that align with business goals while enhancing user experiences. 
					My expertise includes working with modern JavaScript frameworks, TypeScript, Docker, and scalable database 
					systems like MongoDB, always aiming to deliver high-quality results.
				</p>
				<p className="xlg-paragraph lg-margin xl-padding">
					As a developer, I thrive on solving intricate challenges and implementing clean, maintainable code. My passion 
					for technology drives me to stay updated with emerging trends, enabling me to architect innovative systems tailored 
					to client needs. I also enjoy leading technical discussions and collaborating with cross-functional teams, ensuring 
					projects succeed from ideation to deployment. Whether optimizing systems for efficiency or mentoring peers in best practices, 
					I bring a detail-oriented and results-driven mindset to every project. I’m currently focused on advancing my career towards 
					a Principal Architect role while continuing to develop groundbreaking technologies that impact industries and users alike.
				</p>
				<div className="text-center">
					<Link to="/software">
						<button className="lg-button orange-button lg-padding mb-5">See Samples</button>
					</Link>
				</div>
			</div>

			<div className="d-block gray-bg py-5 row text-center">
				<h1 className="main-heading montserrat-font">Professional Design Services</h1>
				<p className="xlg-paragraph lg-margin xl-padding">
					I bring a deep understanding of design principles that merge aesthetics with functionality, creating user interfaces that are 
					not only visually compelling but also highly intuitive. My design expertise lies in crafting seamless user experiences by focusing 
					on clarity, responsiveness, and accessibility. I specialize in translating complex requirements into streamlined designs that resonate 
					with users, ensuring every interaction feels natural and purposeful. From wireframes to polished interfaces, I thrive on transforming 
					concepts into tangible designs that enhance user engagement and deliver measurable results.
				</p>
				<p className="xlg-paragraph lg-margin xl-padding">
					With a strong technical background, I bridge the gap between design and development, enabling smooth handoffs and implementation. My work is 
					grounded in a user-first approach, leveraging tools like Figma and Adobe XD to collaborate efficiently with stakeholders and teams. I am 
					passionate about creating scalable design systems that ensure consistency across products while adapting to evolving needs. Whether working 
					on web applications, mobile interfaces, or branding elements, my attention to detail and commitment to innovation drive designs that align 
					with both user expectations and business objectives.
				</p>
				<div className="text-center">
					<Link to="/design">
						<button className="lg-button orange-button lg-padding mb-5">See Samples</button>
					</Link>
				</div>
			</div>
		</div>
	);
}

function HomeLogos() {
	return(
		<div>
			<div className="mx-auto my-0 row w-75 py-5">
				<div className="col-12 text-center">
					<h1 className="main-heading montserrat-font">Experience & Clients</h1>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Alphagraphics.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Blue.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Cisco.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Discover.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-DISH.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Matthews.png" className="logo-img" alt="" />
					</div>
				</div>

				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 offset-lg-1 offset-md-1 offset-sm-1 offset-xs-0 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-MDRT.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Tranont.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Trimble.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Vivint.png" className="logo-img" alt="" />
					</div>
				</div>
				<div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
					<div className="d-inline-flex logo-tile">
						<img src="https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Logo-Zions.png" className="logo-img" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
}

function CoreSkills() {
	return(
		<div>
			<div className="dark-gray-bg d-block mx-auto py-5 row text-center">
				<h1 className="main-heading montserrat-font white-text">Core Skills</h1>
				<div className="montserrat-font xlg-paragraph xl-padding">
					<div className="d-inline-flex mr-3 skill-tag">Software Development</div>
					<div className="d-inline-flex mr-2 skill-tag">Systems Architecture</div>
					<div className="d-inline-flex mr-2 skill-tag">Cloud Infrastructure</div>
					<div className="d-inline-flex mr-2 skill-tag">Product Leadership</div>
					<div className="d-inline-flex mr-2 skill-tag">Communication</div>
					<div className="d-inline-flex mr-2 skill-tag">Devops</div>
					<div className="d-inline-flex mr-2 skill-tag">Agile</div>
					<div className="d-inline-flex mr-2 skill-tag">Scrum</div>
					<div className="d-inline-flex mr-2 skill-tag">Microservices</div>
					<div className="d-inline-flex mr-2 skill-tag">AWS</div>
					<div className="d-inline-flex mr-2 skill-tag">GCP</div>
					<div className="d-inline-flex mr-2 skill-tag">Azure</div>
					<div className="d-inline-flex mr-2 skill-tag">Node</div>
					<div className="d-inline-flex mr-2 skill-tag">Java</div>
					<div className="d-inline-flex mr-2 skill-tag">Python</div>
					<div className="d-inline-flex mr-2 skill-tag">React</div>
					<div className="d-inline-flex mr-2 skill-tag">Vue</div>
					<div className="d-inline-flex mr-2 skill-tag">Angular</div>
					<div className="d-inline-flex mr-2 skill-tag">REST APIs</div>
					<div className="d-inline-flex mr-2 skill-tag">Postgres</div>
					<div className="d-inline-flex mr-2 skill-tag">MongoDB</div>
					<div className="d-inline-flex mr-2 skill-tag">GraphQL</div>
					<div className="d-inline-flex mr-2 skill-tag">MySQL</div>
					<div className="d-inline-flex mr-2 skill-tag">NoSQL</div>
					<div className="d-inline-flex mr-2 skill-tag">ServiceNow</div>
					<div className="d-inline-flex mr-2 skill-tag">Jira</div>
					<div className="d-inline-flex mr-2 skill-tag">Docker</div>
					<div className="d-inline-flex mr-2 skill-tag">Kubernetes</div>
					<div className="d-inline-flex mr-2 skill-tag">Javascript</div>
					<div className="d-inline-flex mr-2 skill-tag">TypeScript</div>
					<div className="d-inline-flex mr-2 skill-tag">C#</div>
					<div className="d-inline-flex mr-2 skill-tag">.NET</div>
				</div>
			</div>
		</div>
	);
}

function AboutContent() {
	return (
		<div>
			<div className="m-0 row">
				<div className="col-12 col-md-2 offset-md-3 offset-0">
					<img className="profile-img" src="https://bdopersonal.s3.us-west-2.amazonaws.com/Bryan+Social+Icon+001.jpg" alt="" />
				</div>
				<div className="col-12 col-md-7 p-2 p-md-0 text-center text-md-left">
					<h1 className="montserrat-font mt-0 mt-md-5">Bryan O'Connor</h1>
					<h3 className="montserrat-font">Software Engineer & Web Developer</h3>
					<p className="m-0"><FontAwesomeIcon icon={faEnvelope} /><span className="pl-2">bryanoconnordev@gmail.com</span></p>
					<p className="m-0"><FontAwesomeIcon icon={faPhone} /><span className="pl-2">385.272.8087</span></p>
					<p className="m-0 pb-4 pb-md-0">
						<FontAwesomeIcon className="fa-icon" icon={faFileArrowDown} />
						<a href={"https://bdopersonal.s3.us-west-2.amazonaws.com/Bryan+O'Connor+Resume+2024.pdf"} target="_blank" rel="noreferrer">
							<span className="pl-2">Resume</span>
						</a>
					</p>
				</div>
			</div>
		</div>
	);
}

function Home() {
	return (
		<div className="container-fluid p-0">
			<HomeHero />
			<HomeContent />
			<HomeLogos />
			<CoreSkills />
			<AboutContent />
		</div>
	);
}

export default Home;
