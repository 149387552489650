/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Software from './components/Software/Software';
import Design from './components/Design/Design';
import Experience from './components/Experience/Experience';
import ContactForm from './components/Contact/ContactForm';

function App() {
	return (
		<>
			<Navbar />
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route exact path="/software" element={<Software />} />
				<Route exact path="/design" element={<Design />} />
				<Route exact path="/experience" element={<Experience />} />
				<Route exact path="/contact" element={<ContactForm />} />
			</Routes>
			<Footer />
		</>
	)
}

export default App;