/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import '../Extras/Heroes.css';
import '../../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faMobileScreenButton, faLaptopCode, faTrophy } from '@fortawesome/free-solid-svg-icons';

function SoftwareHero() {
	return (
		<div>
			<div className="SoftwareHero Hero">
				<h1 className="hero-banner-text montserrat-font white-text">Customized Software</h1>
				<h1 className="dancing-font hero-banner-text orange-text">Solutions</h1>
			</div>
		</div>
	);
}

function SoftwareContent() {
	return (
		<div>
			<div className="lg-margin xl-padding">
				<div className="m-0 py-0 row">
					<div className="col-12 col-md-3 p-0 text-center text-md-right">
						<div className="d-inline-block mr-0 mr-md-4">
							<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faChartLine} /></span></div>
						</div>
					</div>
					<div className="col-12 col-md-9 p-0 text-center text-md-left">
						<h1 className="main-heading montserrat-font">Business Solutions That Work</h1>
						<p className="xlg-paragraph">
							Running a business shouldn’t be harder than it needs to be. If your operations feel overwhelming or 
							inefficient, I can help. I specialize in building custom software and applications that simplify 
							workflows, automate tasks, and give you better control over your business. Whether it’s managing 
							employees, tracking performance, or improving customer engagement, my solutions are designed to fit 
							your specific needs and deliver measurable results.
						</p>
					</div>
				</div>
			</div>

			<div className="gray-bg">
				<div className="lg-margin xl-padding">
					<div className="m-0 py-0 row">
						<div className="col-12 col-md-3 p-0 text-center text-md-right">
							<div className="d-inline-block mr-0 mr-md-4">
								<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faMobileScreenButton} /></span></div>
							</div>
						</div>
						<div className="col-12 col-md-9 p-0 text-center text-md-left">
							<h1 className="main-heading montserrat-font">Custom Applications for Growth</h1>
							<p className="xlg-paragraph">
								Every business is unique, and your software should be too. I design and develop tailored applications 
								that grow with your business—whether you're a small company looking for your first digital solution or 
								a thriving enterprise in need of advanced tools. From intuitive user interfaces to powerful backend systems, 
								I ensure every application is scalable, user-friendly, and aligned with your goals.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="lg-margin xl-padding">
				<div className="m-0 py-0 row">
					<div className="col-12 col-md-3 p-0 text-center text-md-right">
						<div className="d-inline-block mr-0 mr-md-4">
							<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faLaptopCode} /></span></div>
						</div>
					</div>
					<div className="col-12 col-md-9 p-0 text-center text-md-left">
						<h1 className="main-heading montserrat-font">Empowering Your Business with Technology</h1>
						<p className="xlg-paragraph">
							Technology should empower, not overwhelm. My development process focuses on creating tools that are easy to 
							use and integrate seamlessly into your daily operations. With expertise in web and mobile development, I deliver 
							solutions that save you time, improve efficiency, and help your business stand out in today’s competitive market.
						</p>
					</div>
				</div>
			</div>

			<div className="gray-bg">
				<div className="lg-margin mb-0 xl-padding">
					<div className="m-0 py-0 row">
						<div className="col-12 col-md-3 p-0 text-center text-md-right">
							<div className="d-inline-block mr-0 mr-md-4">
								<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faTrophy} /></span></div>
							</div>
						</div>
						<div className="col-12 col-md-9 p-0 text-center text-md-left">
							<h1 className="main-heading montserrat-font">Let's Build Your Success</h1>
							<p className="xlg-paragraph">
								Your business deserves software that works as hard as you do. Let’s collaborate to create solutions that address 
								your unique challenges, drive efficiency, and set you up for success. Ready to get started? Contact me today, and 
								let’s build something extraordinary.
							</p>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

function Software() {
	return (
		<div className="container-fluid p-0">
			<SoftwareHero />
			<SoftwareContent />
		</div>
	);
}

export default Software;