/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import '../Extras/Heroes.css';
import '../../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn, faBullseye, faUserTie, faPencil  } from '@fortawesome/free-solid-svg-icons';

function DesignHero() {
	return (
		<div>
			<div className="DesignHero Hero">
				<h1 className="hero-banner-text montserrat-font white-text">Professional Design</h1>
				<h1 className="dancing-font hero-banner-text orange-text">Services</h1>
			</div>
		</div>
	);
}

function DesignContent() {
	return (
		<div>
			<div className="lg-margin xl-padding">
				<div className="m-0 py-3 row">
					<div className="col-12 col-md-3 p-0 text-center text-md-right">
						<div className="d-inline-block mr-0 mr-md-4">
							<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faChartColumn} /></span></div>
						</div>
					</div>
					<div className="col-12 col-md-9 p-0 text-center text-md-left">
						<h1 className="main-heading montserrat-font">Design that Delivers Results</h1>
						<p className="xlg-paragraph">
							Your business’s visual identity is more than just a logo or color scheme—it’s how your customers experience 
							and connect with your brand. I specialize in professional design services that bring your ideas to life while 
							keeping your audience front and center. Whether you need a sleek website interface, an engaging mobile app design, 
							or a complete brand overhaul, I create designs that are both visually stunning and purpose-driven.
						</p>
					</div>

				</div>
			</div>

			<div className="gray-bg">
				<div className="lg-margin xl-padding">
					<div className="m-0 py-3 row">

						<div className="col-12 col-md-3 p-0 text-center text-md-right">
							<div className="d-inline-block mr-0 mr-md-4">
								<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faBullseye} /></span></div>
							</div>
						</div>
						<div className="col-12 col-md-9 p-0 text-center text-md-left">
							<h1 className="main-heading montserrat-font">Tailored for Your Vision</h1>
							<p className="xlg-paragraph">
								No two businesses are the same, and your design solutions should reflect that. I work closely with you to understand 
								your goals and translate them into unique designs that align with your brand identity. From wireframes and prototypes to 
								polished designs ready for development, I ensure every element is tailored to your needs and resonates with your target 
								audience.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="lg-margin xl-padding">
				<div className="m-0 py-3 row">
					<div className="col-12 col-md-3 p-0 text-center text-md-right">
						<div className="d-inline-block mr-0 mr-md-4">
							<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faUserTie} /></span></div>
						</div>
					</div>
					<div className="col-12 col-md-9 p-0 text-center text-md-left">
						<h1 className="main-heading montserrat-font">User-focused, Business-minded</h1>
						<p className="xlg-paragraph">
							Great design isn’t just about looking good—it’s about delivering an exceptional user experience that drives engagement and 
							boosts conversions. I combine a user-first mindset with a strategic approach to ensure that every design enhances functionality 
							while meeting your business objectives. Whether it’s creating seamless navigation, responsive layouts, or accessible interfaces, 
							I focus on solutions that work as beautifully as they look.
						</p>
					</div>
				</div>
			</div>

			<div className="gray-bg">
				<div className="lg-margin mb-0 xl-padding">
					<div className="m-0 py-3 row">
						<div className="col-12 col-md-3 p-0 text-center text-md-right">
							<div className="d-inline-block mr-0 mr-md-4">
								<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faPencil} /></span></div>
							</div>
						</div>
						<div className="col-12 col-md-9 p-0 text-center text-md-left">
							<h1 className="main-heading montserrat-font">Design the Future of Your Brand</h1>
							<p className="xlg-paragraph">
								Your business deserves designs that make a lasting impression. Let me help you create visuals and experiences that set you apart 
								from the competition. Whether you’re building from scratch or refreshing your existing presence, I’m here to bring your vision to 
								life. Ready to elevate your brand? Contact me today to get started!
							</p>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

function Design() {
	return (
		<div className="container-fluid p-0">
			<DesignHero />
			<DesignContent />
		</div>
	);
}

export default Design;